<template>
  <div class="Debug">
    <h1 v-text="page" />
    <h2>pages</h2>
    <ul>
      <li><router-link :to="{ name: 'Top', params: { lang: locale } }">1-1,2,3 トップページ</router-link></li>
      <li><router-link :to="{ name: 'Vote', params: { lang: locale } }">2 属性選択ページ</router-link></li>
      <li><router-link :to="{ name: 'HeroList', params: { lang: locale, heroType: 'hero-type-1'} }">3-1 英雄一覧</router-link></li>
      <li><router-link :to="{ name: 'Hero', params: { lang: locale, heroType: 'hero-type-1', heroId: '1'} }">4-1,4-5 投票ページ,投票完了ページ</router-link></li>
      <li><router-link :to="{ name: 'HeroVoted', params: { lang: locale, heroType: 'hero-type-1', heroId: '1'} }">4-3 投票済みページ</router-link></li>
      <li><router-link :to="{ name: 'MyPage', params: { lang: locale } }">5-1 マイページ</router-link></li>
      <li><router-link :to="{ name: 'Result', params: { lang: locale } }">6-2 結果発表ページ（上位）</router-link></li>
      <li><router-link :to="{ name: 'ResultList', params: { lang: locale, page: '1' } }">6-3 結果発表ページ（全部）</router-link></li>
      <li><router-link :to="{ name: 'Error', params: { lang: locale } }">100 エラーページ（汎用）</router-link></li>
      <li><router-link :to="`/${locale}/hoge`">100 エラーページ（404）</router-link></li>
      <li><router-link :to="{name: 'Error', params: {lang: locale}, query: {error: 'login'}}">100 エラーページ（ログインエラー）</router-link></li>
    </ul>
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'

export default {
  name: 'Debug',
  mixins: [LocaleMixin, LoaderMixin],
  data () {
    return {
      page: '99 デバッグページ'
    }
  }
}
</script>

<style lang="scss" scoped>
.Debug {
  padding: 40px;
  h1, h2 {
    font-size: 30px;
    color: #000;
    font-weight: bold;
  }
  li,
  li > a {
    text-decoration: underline;
    color: #0000ff;
    line-height: 1.8;
  }
}
</style>
